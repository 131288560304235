import React from 'react';
import {Image} from "semantic-ui-react";

const imgPath = './assets/images/clients/';

const Clients = () => {
    return (
        <div className='headings'>
            <h3 className='section-heading'>CLIENTS</h3>
            <div className='ui container center aligned' style={{opacity: '0.7'}}>
                <Image.Group size='tiny'>
                    <Image src={`${imgPath}hbo.png`} />
                    <Image src={`${imgPath}netflix.png`} />
                    <Image src={`${imgPath}hulu.png`} />
                    <Image src={`${imgPath}amazon.png`} />
                    <Image src={`${imgPath}marvel.png`} />
                    <Image src={`${imgPath}a&e.png`} />
                    <Image src={`${imgPath}disney.png`} />
                    <Image src={`${imgPath}paramount.png`} />
                    <Image src={`${imgPath}universal.png`} />
                </Image.Group>
            </div>
        </div>
    );
};

export default Clients;