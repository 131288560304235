import React from 'react';
import ReactDOM from 'react-dom';

// styles
import 'semantic-ui-css/semantic.min.css';
import './styles/styles.scss';

import App from "./App";

ReactDOM.render (
    <App />, document.getElementById('root')
);


