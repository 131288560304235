import React from 'react';
import { Modal, Image, Embed } from 'semantic-ui-react';
import ReactPlayer from "react-player";

const imagePath = './assets/images/design/';
const videoPath = './assets/video/';
const adPath = './assets/ads/';

const getMedia = (item) => {
    switch (item.type) {
        case 'image': {
            return <Image
                src={`${imagePath}${item.media}`}
            />
        }
        case 'video': {
            return <ReactPlayer
                playing={true}
                url={`${videoPath}${item.media}`}
                width='100%'
                height='100%'
                controls={true}
            />
        }
        case 'url': {
            return <iframe
                src={`${adPath}${item.media}`}
                width={item.width}
                height={item.height}
                style={{border: 'none'}}
            />
        }
    }
};

const MediaModal = (props) => {
    return (
        <Modal closeIcon open={props.open} onClose={props.closeModal} size='large'>
            <Modal.Header>
                {props.item.title}
                <h4>{props.item.client}</h4>
            </Modal.Header>

            <Modal.Content image style={{justifyContent: 'center'}}>
                {getMedia(props.item)}
            </Modal.Content>

            <Modal.Description>
                <h4 style={{textAlign: 'center', margin: 0, paddingBottom: 20}}>{props.item.desc}</h4>
            </Modal.Description>
        </Modal>
    );
};

export default MediaModal;