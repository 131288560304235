export const MediaData = {
    thumbPath: './assets/images/thumbs/',
    imagePath: './assets/images/design/',
    adPath: './assets/ads/',
    data: [
        {
            title: 'Ocean Entertainment',
            client: 'Ocean Entertainment Productions',
            desc: 'Opening Title Sequence - Cinema 4D, Vue XStream, After Effects',
            type: 'video',
            thumb: 'oceanEnt_thumb.jpg',
            media: 'oceanEnt.mp4'
        },

        // {
        //     title: 'Amazon - Pete the Cat',
        //     desc: '',
        //     type: 'url',
        //     thumb: 'peteTheCat_thumb.jpg',
        //     media: 'pete-the-cat/index.html',
        //     width: 300,
        //     height: 600
        // },

        {
            title: 'The Book of Mormon',
            client: 'Scott Rudin Productions',
            desc: 'Domestic :30 Spot - After Effects, Photoshop',
            type: 'video',
            thumb: 'bom_thumb.jpg',
            media: 'bookOfMormon.mp4'
        },

        {
            title: '1984',
            client: 'Scott Rudin Productions',
            desc: 'Domestic :30 Spot - After Effects',
            type: 'video',
            thumb: '1984_thumb.jpg',
            media: '1984_TVSpot_02.mp4'
        },

        {
            title: 'Carousel',
            client: 'Scott Rudin Productions',
            desc: 'Domestic :30 Spot - After Effects',
            type: 'video',
            thumb: 'carousel_thumb.jpg',
            media: 'carousel.mp4'
        },

        // {
        //     title: 'Curb Your Enthusiasm',
        //     client: 'HBO',
        //     desc: 'Ad Campaign - HTML, CSS, Javascript',
        //     type: 'video',
        //     thumb: 'curb_thumb.jpg',
        //     media: 'curbEnthusiasm.mp4',
        // },

        {
            title: "A Doll's House",
            client: 'Scott Rudin Productions',
            desc: 'Domestic 1:00 Spot - After Effects, Photoshop',
            type: 'video',
            thumb: 'dollsHouse_thumb.jpg',
            media: 'DollsHouse_TVSpot_01.mp4'
        },

        {
            title: "Fish in the Dark",
            client: 'Scott Rudin Productions',
            desc: 'Domestic :15 Spot - After Effects',
            type: 'video',
            thumb: 'fishDark_thumb.jpg',
            media: 'fishDark.mp4'
        },

        {
            title: 'Gary',
            client: 'Scott Rudin Productions',
            desc: 'Domestic :30 Spot - After Effects, Photoshop',
            type: 'video',
            thumb: 'gary_thumb.jpg',
            media: 'Gary_TVSpot_01.mp4'
        },

        // {title: 'The Glass Menagerie', type: 'video',
        //     media: '',
        //     thumb: 'glassMenagerie_thumb.jpg'},

        {
            title: 'Hello Dolly',
            client: 'Scott Rudin Productions',
            desc: 'Ad Campaign - HTML, CSS, Javascript',
            type: 'video',
            thumb: 'helloDolly_thumb.jpg',
            media: 'helloDolly.mp4'
        },

        {
            title: 'Hillary and Clinton',
            client: 'Scott Rudin Productions',
            desc: 'Domestic :30 Spot - After Effects',
            type: 'video',
            thumb: 'hillary_thumb.jpg',
            media: 'HillaryAndClinton_TVSpot_01.mp4'
        },

        // {
        //     title: 'Humans',
        //     client: 'Scott Rudin Productions',
        //     desc: 'Ad Campaign - HTML, CSS, Javascript',
        //     type: 'video',
        //     thumb: 'humans_thumb.jpg',
        //     media: 'humans.mp4',
        // },

        {
            title: 'The Iceman Cometh',
            client: 'Scott Rudin Productions',
            desc: 'Domestic :30 Spot - After Effects',
            type: 'video',
            thumb: 'iceman_thumb.jpg',
            media: 'Iceman_TVSpot_01.mp4'
        },

        {
            title: 'King Lear',
            client: 'Scott Rudin Productions',
            desc: 'Domestic :15 Spot - After Effects',
            type: 'video',
            thumb: 'kingLear_thumb.jpg',
            media: 'KingLear_TVSpot_01.mp4'
        },

        {
            title: 'The Music Man',
            client: 'Scott Rudin Productions',
            desc: 'Domestic :30 Spot - After Effects',
            type: 'video',
            thumb: 'musicMan_thumb.jpg',
            media: 'musicMan.mp4'
        },

        // {title: 'Ready Player One', type: 'video',
        //     media: '',
        //     thumb: 'readyPlayerOne_thumb.jpg'},

        {
            title: 'Shuffle Along',
            client: 'Scott Rudin Productions',
            desc: 'Domestic :30 Spot - After Effects',
            type: 'video',
            thumb: 'shuffleAlong_thumb.jpg',
            media: 'shuffleAlong.mp4'
        },

        // {
        //     title: 'The Front Page',
        //     client: 'Scott Rudin Productions',
        //     desc: 'Ad Campaign - HTML, CSS, Javascript',
        //     type: 'video',
        //     thumb: 'frontPage_thumb.jpg',
        //     media: 'frontPage.mp4',
        // },

        {
            title: 'The Waverly Gallery',
            client: 'Scott Rudin Productions',
            desc: 'Domestic :15 Spot - After Effects',
            type: 'video',
            thumb: 'waverly_thumb.jpg',
            media: 'WaverlyGallery_TVSpot_01.mp4'
        },

        {
            title: 'Three Tall Women',
            client: 'Scott Rudin Productions',
            desc: 'Domestic :30 Spot - After Effects',
            type: 'video',
            thumb: 'ttw_thumb.jpg',
            media: 'TTW_TVSpot_01.mp4'
        },

        {
            title: 'To Kill a Mockingbird',
            client: 'Scott Rudin Productions',
            desc: 'Domestic 1:00 Spot - After Effects',
            type: 'video',
            thumb: 'tkam_thumb.jpg',
            media: 'TKAM_TVSpot_01.mp4'
        },

        {
            title: 'A View From a Bridge',
            client: 'Scott Rudin Productions',
            desc: 'Website - HTML, CSS, Javascript',
            type: 'video',
            thumb: 'viewFromBridge_thumb.jpg',
            media: 'viewFromBridge.mp4'
        },

        {
            title: "Who's Afraid of Virginia Woolf",
            client: 'Scott Rudin Productions',
            desc: 'Domestic :30 Spot - After Effects',
            type: 'video',
            thumb: 'virginiaWoolf_thumb.jpg',
            media: 'WAOVW_TVSpot_01.mp4'
        },

        {
            title: 'West Side Story',
            client: 'Scott Rudin Productions',
            desc: 'Website Splash Page - HTML, CSS, Javascript',
            type: 'video',
            thumb: 'wss_thumb.jpg',
            media: 'WSS_Website_Splash.mp4'
        },

        {
            title: 'Varekai Preview DVD',
            client: 'Cirque du Soleil',
            desc: 'UI and DVD Authoring - Photoshop, After Effects',
            type: 'image',
            thumb: 'varekai_thumb.jpg',
            media: 'varekai.jpg'
        },

        {
            title: "WhiteFence",
            client: 'Allconnect, Inc.',
            desc: 'Opening Animation & UI - After Effects, Photoshop',
            type: 'video',
            thumb: 'whitefence_thumb.jpg',
            media: 'whitefence.mp4'
        },

        {
            title: 'Busch Gardens / American Express Promo',
            client: 'Busch Entertainment',
            desc: 'Intro and UI - After Effects, Photoshop',
            type: 'video',
            thumb: 'amexBusch_thumb.jpg',
            media: 'amexBusch.mp4'
        },

        {
            title: 'Wynn Ambassador',
            client: 'Wynn Resorts',
            desc: 'Intro and UI Demo - Cinema 4D',
            type: 'video',
            thumb: 'wynnAmbassador_thumb.jpg',
            media: 'wynnAmbassador.mp4'
        },

        {
            title: 'Valeroso Logo Design',
            client: 'Valeroso Cigar Company',
            desc: 'Cinema 4D, Photoshop, Illustrator',
            type: 'image',
            thumb: 'valeroso_thumb.jpg',
            media: 'valeroso.jpg'
        },

        {
            title: 'Knuckle Draggers',
            client: 'Partners in Crime Films',
            desc: 'Opening Title - After Effects, Photoshop',
            type: 'video',
            thumb: 'knuckledraggers_thumb.jpg',
            media: 'Knuckledraggers.mp4'
        },
    ]
}

/* TODO:
    videos:
        glass menagerie
        ready player one
        The Front Page
        Curb
        Humans
 */
