import React, {Component, Fragment} from 'react';
import {Segment, Image, Button, Form, TextArea, Grid} from 'semantic-ui-react';
import emailjs from 'emailjs-com';

import Clients from "../components/Clients";

const imgPath = './assets/images/misc/brian_bw_2.jpg';

const segmentStyle = {
    background: 'none',
    boxShadow: 'none',
};

const textStyle = {
    color: '#c1c1c1',
    letterSpacing: 1,
    marginBottom: 20,
    width: '72%',
};

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            first_name: '',
            last_name: '',
            user_email: '',
            message: ''
        };

        emailjs.init('user_6nNRjSPYn4kwddOtud5Cy');
    }

    sendEmail = (e) => {
        e.preventDefault();
        const service_id = 'service_353cybu';
        const template_id = 'template_z6excnl';

        emailjs.sendForm(service_id, template_id, e.target)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        this.setState({first_name: '', last_name: '', user_email: '', message: ''});
    }

    handleFields = e => this.setState({[e.target.name]: e.target.value});

    render() {
        return (
            <Segment style={segmentStyle}>
                <Grid style={{display: 'flex', justifyContent: 'center'}}>
                    <Grid.Row textAlign='justified'>
                        <h3 style={textStyle}>
                            I'm currently available for freelance and full-time work. If you're in need of an
                            experienced multimedia developer for your next project, please contact me using the form below
                            and I'll get back to you promptly. I look forward in hearing from you!
                        </h3>
                    </Grid.Row>

                    <Grid.Column width={12}>
                        <Form inverted onSubmit={this.sendEmail}>
                            <Form.Group widths='equal'>
                                <Form.Field required>
                                    <label>First Name</label>
                                    <input placeholder='First Name' name="first_name" value={this.state.first_name}
                                           onChange={this.handleFields}/>
                                </Form.Field>

                                <Form.Field required>
                                    <label>Last Name</label>
                                    <input placeholder='Last Name' name="last_name" value={this.state.last_name}
                                           onChange={this.handleFields}/>
                                </Form.Field>
                            </Form.Group>

                            <Form.Field required>
                                <label>Email</label>
                                <input placeholder='Email' name="user_email" value={this.state.user_email}
                                       onChange={this.handleFields}/>
                            </Form.Field>

                            <Form.Field
                                required
                                control={TextArea}
                                label='Message'
                                placeholder='Message'
                                style={{height: '220px'}}
                                name="message"
                                value={this.state.message}
                                onChange={this.handleFields}
                            />

                            <Button type='submit' value="Send">Submit</Button>
                        </Form>
                    </Grid.Column>
                </Grid>

                <Clients/>
            </Segment>
        );
    };
}

export default Contact;
