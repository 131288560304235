import React from "react";
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const MainMenu = () => {
    const menuData = [
        {name: 'work', link: '/', position: 'right'},
        // {name: 'development', link: '/development'},
        {name: 'about', link: '/about'},
        {name: 'contact', link: '/contact'}
    ]

    const menuItems = menuData.map((item, index) => {
        return (
            <Menu.Item
                as={NavLink}
                exact={true}
                to={item.link}
                className='menuItem'
                name={item.name}
                position={item.position}
                color={'green'}
                key={index}
            />
        );
    });

    return (
        <div className='ui container'>
            <Menu className='secondary'>
                {menuItems}
            </Menu>
        </div>
    );
};

export default MainMenu;