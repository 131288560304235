import React, { useState } from 'react';
import { Segment, Grid, Image } from 'semantic-ui-react';
import { MediaData } from '../data/MediaData';

import MediaModal from "../components/MediaModal";

const Projects = () => {
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState('');

    const handleOpen = (e) => {
        setItem(MediaData.data[e.target.id]);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    return (
        <Segment style={{background: 'none', boxShadow: 'none'}}>
            <Grid doubling columns={4}>
                {MediaData.data.map((item, index) => (
                    <Grid.Column key={index}>
                        <Image
                            className='image-style'
                            src={`${MediaData.thumbPath}${item.thumb}`}
                            onClick={handleOpen}
                            style={{cursor: 'pointer'}}
                            id={index}
                        />
                    </Grid.Column>
                ))}
            </Grid>

            <MediaModal open={open} closeModal={handleClose} item={item} />
        </Segment>
    );
};

export default Projects;