import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './styles/styles.scss';

import { Divider } from "semantic-ui-react";

import MainMenu from './components/MainMenu';
import Footer from './components/Footer';
import About from './pages/About';
import Projects from './pages/Projects';
import Development from './pages/Development';
import Contact from './pages/Contact';

const App = () => {
  return (
      <div className='main-container ui container'>
          <Router>
              <MainMenu />
              <Divider />
              <div>
                  <Route path='/' exact component={Projects} />
                  <Route path='/development' component={Development} />
                  <Route path='/about' component={About} />
                  <Route path='/contact' component={Contact} />
              </div>
          </Router>

          <Footer className='footer' />
      </div>
  );
}

export default App;
