import React from 'react';
import { Icon, InlineIcon } from '@iconify/react';

// icons
import fileTypeHtml from '@iconify-icons/vscode-icons/file-type-html';
import fileTypeCss from '@iconify-icons/vscode-icons/file-type-css';
import sassIcon from '@iconify-icons/logos/sass';
import reactIcon from '@iconify-icons/logos/react';
import reduxIcon from '@iconify-icons/logos/redux';
import cIcon from '@iconify-icons/logos/c';
import cSharp from '@iconify-icons/logos/c-sharp';
import pythonIcon from '@iconify-icons/logos/python';
import gitIcon from '@iconify-icons/logos/git-icon';
import gulpIcon from '@iconify-icons/simple-icons/gulp';
import unityIcon from '@iconify-icons/cib/unity';
import adobePhotoshop from '@iconify-icons/cib/adobe-photoshop';
import adobeIllustrator from '@iconify-icons/cib/adobe-illustrator';
import adobeAfterEffects from '@iconify-icons/cib/adobe-after-effects';

const colors = {
    adobeBlue: '#3da3f7',
    adobeOrange: '#f79500',
    adobePurple: '#9494f7',
    gulp: '#cf4647',
    unity: '#c1c1c1'
};

const iconStyles = {
    color: '#ffffff',
    width: '48',
    height: '48',
    margin: '8px'
};

const Skills = () => {
    return (
        <div className='ui container center aligned' style={{marginTop: 30}}>
            {/*<h3 className='section-heading'>SKILLS</h3>*/}

            <Icon icon={fileTypeHtml} style={iconStyles} />
            <Icon icon={fileTypeCss} style={iconStyles} />
            <Icon icon={sassIcon} style={iconStyles} />
            <Icon icon={reactIcon} style={iconStyles} />
            <Icon icon={reduxIcon} style={iconStyles} />
            <Icon icon={cIcon} style={iconStyles} />
            <Icon icon={cSharp} style={iconStyles} />
            <Icon icon={pythonIcon} style={iconStyles} />
            <Icon icon={gitIcon} style={iconStyles} />
            <Icon icon={gulpIcon} style={iconStyles} color={colors.gulp} />
            <Icon icon={adobePhotoshop} style={iconStyles} color={colors.adobeBlue} />
            <Icon icon={adobeIllustrator} style={iconStyles} color={colors.adobeOrange} />
            <Icon icon={adobeAfterEffects} style={iconStyles} color={colors.adobePurple} />
            <Icon icon={unityIcon} style={iconStyles} color={colors.unity} />
        </div>
    );
};

export default Skills;