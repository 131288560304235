import React from 'react';
import {Segment, Grid} from 'semantic-ui-react';
import Intro from "../components/Intro";
import Skills from "../components/Skills";

const segmentStyle = {
    background: 'none',
    boxShadow: 'none'
}

const textStyle = {
    color: '#c1c1c1',
    letterSpacing: 1,
    marginBottom: 20,
    textAlign: 'justify',
}

const About = () => {
    return (
        <Segment style={segmentStyle}>
            <Intro/>

            <Grid centered>
                <Grid.Column width={14}>
                    <h3 style={textStyle}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        I'm Brian, a senior multimedia developer with over 25 years of experience in design, motion
                        graphics, and development, specializing in rich media campaigns and marketing strategies. I
                        possess a strong passion for technology and animation, and am well-versed in development,
                        motion graphics, and video production.
                    </h3>

                    <h3 style={textStyle}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        I have always had a love for computers and the digital arts. I began using Photoshop in 1991
                        (ver. 2.0), and After Effects in 1994 (ver. 2.0.1), and quickly got interested in video editing,
                        animation, and multimedia. I was a pioneer in creating digital press kits for the film and
                        television industry, and was one of the leading multimedia developers in Los Angeles.
                    </h3>

                    <h3 style={textStyle}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Lately I’ve been pursuing Augmented Reality and the role it can play in advertising and
                        marketing. I developed an AR app for the Broadway musical <i>The Book of Mormon</i> (available
                        on the App Store and Google Play), and several AR demos to promote the film <i>Ready Player
                        One</i>. I’m also expanding my development skills in mobile development and learning native languages
                        such as Swift and Kotlin.
                    </h3>
                </Grid.Column>
            </Grid>

            <Skills/>
        </Segment>
    );
};

export default About;